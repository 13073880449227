
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarReports from "@/components/sidebar-reports.vue";

@Component({
  components: {
    Header,
    SidebarReports,
  },
})
export default class VatReturn extends Vue {
  private data: any[] = [];
  public filter = null;
  public filterOn = [];
  trans = [];
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  // public fields = [
  //   {
  //     key: "account",
  //   },
  //   {
  //     key: "description",
  //   },
  //   {
  //     key: "balance",
  //   },
  //   {
  //     key: "total",
  //   },
  // ];
  get rows() {
    return this.selected_items.length;
  }

  public items = [];
  public selected_items = [
    {
      SNo: "1",
      type: "",
      particulars: "",
      amount: "",
      adjustment: "",
      VATAmount: "",
    },
  ];
  retrieve() {
    // HRMService.getAll()
    //   .then((response) => {
    //     this.items = response.data;
    //     this.selected_items = this.items;
    //     console.log(this.items)
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  // refreshList() {
  //   this.retrieve();
  //   this.currentItem = null;
  //   this.currentIndex = -1;
  // }

  // setActiveItem(data: any, index: number) {
  //   this.currentItem = data;
  //   this.currentIndex = index;
  // }

  // removeAll() {
  //   HrmService.deleteAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       this.refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // searchTitle() {
  //   HRMService.findByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // mounted() {
  //   this.retrieve();
  // }
}
